//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios"
import ThePopup from "~/components/ThePopup";
import config from "~/config"
import Fz from "~/components/fz";
//import SmartCaptcha from "~/components/SmartCaptcha/SmartCaptcha";

const MAIL_URL = `${config.API}/mail`;

export default {
  name: "OrderForm",
  props: {
    title: {
      type: String,
      default: "Получить консультацию"
    },
    button: {
      type: String,
      default: "Отправить"
    },
    site: Boolean,
    mail: Boolean,
    goal: String
  },
  components: {
 //   SmartCaptcha,
    Fz,
    ThePopup
  },
  data() {
    return {
   //   smartCaptchaResponse: null,
    //  isErrorCaptcha: false,
      form: {
        name: "",
        phone: "",
        comment: "",
        site: "",
        email: "",
        cms: "",
        hosting: "",
        smartToken: ""
      },
      success: false,
      step: 1
    }
  },
  methods: {
    setSmartToken(response) {
      this.form.smartToken = response
    },
    submit() {
      let TRACE = null;
      if (typeof b24Tracker !== 'undefined') {
        TRACE = b24Tracker.guest.getTrace();
      }
    //  if (!this.form.smartToken) {
    //    if(!this.isErrorCaptcha) this.isErrorCaptcha = true
    //    return
    //  }
      this.success = true;
      if (this.goal) this.$sendGoal(this.goal);

      return axios.post(MAIL_URL, Object.assign(this.form, {
        mail_subject: this.title,
        client_id: this.$cookies.get("utmstat_client_id"),
        current_url: document.location.href,
        TRACE: JSON.parse(TRACE)
      })).then(() => {
        this.form.name = "";
        this.form.phone = "";
        this.form.promocode = "";
        this.form.comment = "";
        this.step = 1;
        this.form.email = "";
        this.form.site = "";
        this.form.cms = "";
        this.form.hosting = "";
     //   this.isErrorCaptcha = false;
      });
    }
  }
}
