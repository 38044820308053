//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheCheck from "~/components/TheCheck";
import FAQ from "~/components/FAQ";
import about from "~/components/about";
import TheFooter from "~/components/TheFooter";
import TheYouTube from "~/components/TheYouTube";
import ScanScreen from "~/components/ScanScreen";
import OrderForm from "~/components/OrderForm";
import TheRatingsList from "@/components/TheRatingsList.vue";
import topPanel from "~/components/topPanel";
import chromeEx from '~/components/chromeEx';
import compareWidget from '~/components/compareWidget';
import stat from '~/components/stat';
import blog from '~/components/blog';

export default {
  name: "index",
  components: {
    FAQ,
    stat,
    about,
    TheRatingsList,
    OrderForm,
    ScanScreen,
    TheYouTube,
    TheCheck,
    TheFooter,
    topPanel,
    blog,
    chromeEx,
    compareWidget
  },
  httpHeaders: () => ({
    'Last-Modified': new Date().toGMTString()
  }),
  head() {
    return {
      title: 'Проверить скорость сайта онлайн сервис. Скорость загрузки сайта.',
      meta: [
        {hid: "description", name: "description", content: 'Проверить скорость загрузки сайта и страниц. Что влияет на скорость сайта. Как анализировать тесты скорости страниц? Получите бесплатную консультацию!'},
        {hid: "og:locale", property: "og:locale", content: "ru"},
        {hid: "og:title", property: "og:title", content: 'Проверить скорость загрузки сайта онлайн'},
        {hid: "og:description", property: "og:description", content: 'Проверить скорость загрузки своего сайта и страниц. Что влияет на скорость сайта. Как анализировать тесты скорости страниц?'},
        {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
        {hid: "twitter:image", property: "twitter:image", content: "https://loading.express/og-ru.jpg"},
        {hid: "twitter:title", property: "twitter:title", content: "Проверить скорость загрузки сайта онлайн. Скорость загрузки сайтов."},
        {hid: "twitter:description", property: "twitter:description", content: "Проверить скорость загрузки сайта и страниц. Что влияет на скорость сайта. Как анализировать тесты скорости страниц? Получите бесплатную консультацию!"},
      ],
      link: [
        {rel: "alternate", hreflang: "x-default", href: "https://loading.express/"},
        {rel: "alternate", hreflang: "ru-ru", href: "https://loading.express/"},
        {rel: "alternate", hreflang: "en-us", href: "https://load.express"}

      ]
    }
  },
  async fetch({store}) {
    await store.dispatch("GET_RATINGS_LIST");
    await store.dispatch("GET_YOUTUBE_LIST");
    await store.dispatch("GET_BLOG_LIST");
    await store.dispatch("GET_STATISTICS");
  }
}
